import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery, Link } from 'gatsby';
import {useQuery, gql} from '@apollo/client';
import { List, ListItem } from '@paljs/ui/List';
import { Card } from '@paljs/ui/Card';
import User from '@paljs/ui/User';

const Articles = () => {
    const q = gql`
    query {
        allArticles{
        title
        _key
      }
    }
    `;

    const {data, loading, error} = useQuery(q)

    if (loading) {
        return <>loading...</>
    } 

    if (error) {
        console.log(error)
        return <>error...</>
    }

    const articles = data.allArticles;

    return(
        <>
            <Row>
                <Col xs={12}>
                    <h2 style={{textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em'}}>All Articles</h2>
                </Col>
                <Col xs={12} md={6}>
                    <Card>
                    <List>
                        {articles.map((article, index) => (
                            <a href={`/articles/edit-article?key=${article._key}`}><ListItem key={index}>{article.title}</ListItem></a>
                        ))}
                    </List>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Articles;